var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

"function" != typeof Object.create && (Object.create = function (t) {
  function o() {}

  return o.prototype = t, new o();
}), function (t, o, i, s) {
  "use strict";

  var n = {
    _positionClasses: ["bottom-left", "bottom-right", "top-right", "top-left", "bottom-center", "top-center", "mid-center"],
    _defaultIcons: ["success", "error", "info", "warning"],
    init: function (o, i) {
      this.prepareOptions(o, t.toast.options), this.process();
    },
    prepareOptions: function (o, i) {
      var s = {};
      "string" == typeof o || o instanceof Array ? s.text = o : s = o, (this || _global).options = t.extend({}, i, s);
    },
    process: function () {
      this.setup(), this.addToDom(), this.position(), this.bindToast(), this.animate();
    },
    setup: function () {
      var o = "";

      if ((this || _global)._toastEl = (this || _global)._toastEl || t("<div></div>", {
        "class": "jq-toast-single"
      }), o += "<span class=\"jq-toast-loader\"></span>", (this || _global).options.allowToastClose && (o += "<span class=\"close-jq-toast-single\">&times;</span>"), (this || _global).options.text instanceof Array) {
        (this || _global).options.heading && (o += "<h2 class=\"jq-toast-heading\">" + (this || _global).options.heading + "</h2>"), o += "<ul class=\"jq-toast-ul\">";

        for (var i = 0; i < (this || _global).options.text.length; i++) o += "<li class=\"jq-toast-li\" id=\"jq-toast-item-" + i + "\">" + (this || _global).options.text[i] + "</li>";

        o += "</ul>";
      } else (this || _global).options.heading && (o += "<h2 class=\"jq-toast-heading\">" + (this || _global).options.heading + "</h2>"), o += (this || _global).options.text;

      (this || _global)._toastEl.html(o), (this || _global).options.bgColor !== !1 && (this || _global)._toastEl.css("background-color", (this || _global).options.bgColor), (this || _global).options.textColor !== !1 && (this || _global)._toastEl.css("color", (this || _global).options.textColor), (this || _global).options.textAlign && (this || _global)._toastEl.css("text-align", (this || _global).options.textAlign), (this || _global).options.icon !== !1 && ((this || _global)._toastEl.addClass("jq-has-icon"), -1 !== t.inArray((this || _global).options.icon, (this || _global)._defaultIcons) && (this || _global)._toastEl.addClass("jq-icon-" + (this || _global).options.icon)), (this || _global).options["class"] !== !1 && (this || _global)._toastEl.addClass((this || _global).options["class"]);
    },
    position: function () {
      "string" == typeof (this || _global).options.position && -1 !== t.inArray((this || _global).options.position, (this || _global)._positionClasses) ? "bottom-center" === (this || _global).options.position ? (this || _global)._container.css({
        left: t(o).outerWidth() / 2 - (this || _global)._container.outerWidth() / 2,
        bottom: 20
      }) : "top-center" === (this || _global).options.position ? (this || _global)._container.css({
        left: t(o).outerWidth() / 2 - (this || _global)._container.outerWidth() / 2,
        top: 20
      }) : "mid-center" === (this || _global).options.position ? (this || _global)._container.css({
        left: t(o).outerWidth() / 2 - (this || _global)._container.outerWidth() / 2,
        top: t(o).outerHeight() / 2 - (this || _global)._container.outerHeight() / 2
      }) : (this || _global)._container.addClass((this || _global).options.position) : "object" == typeof (this || _global).options.position ? (this || _global)._container.css({
        top: (this || _global).options.position.top ? (this || _global).options.position.top : "auto",
        bottom: (this || _global).options.position.bottom ? (this || _global).options.position.bottom : "auto",
        left: (this || _global).options.position.left ? (this || _global).options.position.left : "auto",
        right: (this || _global).options.position.right ? (this || _global).options.position.right : "auto"
      }) : (this || _global)._container.addClass("bottom-left");
    },
    bindToast: function () {
      var t = this || _global;
      (this || _global)._toastEl.on("afterShown", function () {
        t.processLoader();
      }), (this || _global)._toastEl.find(".close-jq-toast-single").on("click", function (o) {
        o.preventDefault(), "fade" === t.options.showHideTransition ? (t._toastEl.trigger("beforeHide"), t._toastEl.fadeOut(function () {
          t._toastEl.trigger("afterHidden");
        })) : "slide" === t.options.showHideTransition ? (t._toastEl.trigger("beforeHide"), t._toastEl.slideUp(function () {
          t._toastEl.trigger("afterHidden");
        })) : (t._toastEl.trigger("beforeHide"), t._toastEl.hide(function () {
          t._toastEl.trigger("afterHidden");
        }));
      }), "function" == typeof (this || _global).options.beforeShow && (this || _global)._toastEl.on("beforeShow", function () {
        t.options.beforeShow();
      }), "function" == typeof (this || _global).options.afterShown && (this || _global)._toastEl.on("afterShown", function () {
        t.options.afterShown();
      }), "function" == typeof (this || _global).options.beforeHide && (this || _global)._toastEl.on("beforeHide", function () {
        t.options.beforeHide();
      }), "function" == typeof (this || _global).options.afterHidden && (this || _global)._toastEl.on("afterHidden", function () {
        t.options.afterHidden();
      });
    },
    addToDom: function () {
      var o = t(".jq-toast-wrap");

      if (0 === o.length ? (o = t("<div></div>", {
        "class": "jq-toast-wrap"
      }), t("body").append(o)) : (!(this || _global).options.stack || isNaN(parseInt((this || _global).options.stack, 10))) && o.empty(), o.find(".jq-toast-single:hidden").remove(), o.append((this || _global)._toastEl), (this || _global).options.stack && !isNaN(parseInt((this || _global).options.stack), 10)) {
        var i = o.find(".jq-toast-single").length,
            s = i - (this || _global).options.stack;
        s > 0 && t(".jq-toast-wrap").find(".jq-toast-single").slice(0, s).remove();
      }

      (this || _global)._container = o;
    },
    canAutoHide: function () {
      return (this || _global).options.hideAfter !== !1 && !isNaN(parseInt((this || _global).options.hideAfter, 10));
    },
    processLoader: function () {
      if (!this.canAutoHide() || (this || _global).options.loader === !1) return !1;

      var t = (this || _global)._toastEl.find(".jq-toast-loader"),
          o = ((this || _global).options.hideAfter - 400) / 1000 + "s",
          i = (this || _global).options.loaderBg,
          s = t.attr("style") || "";

      s = s.substring(0, s.indexOf("-webkit-transition")), s += "-webkit-transition: width " + o + " ease-in;                       -o-transition: width " + o + " ease-in;                       transition: width " + o + " ease-in;                       background-color: " + i + ";", t.attr("style", s).addClass("jq-toast-loaded");
    },
    animate: function () {
      var t = this || _global;

      if ((this || _global)._toastEl.hide(), (this || _global)._toastEl.trigger("beforeShow"), "fade" === (this || _global).options.showHideTransition.toLowerCase() ? (this || _global)._toastEl.fadeIn(function () {
        t._toastEl.trigger("afterShown");
      }) : "slide" === (this || _global).options.showHideTransition.toLowerCase() ? (this || _global)._toastEl.slideDown(function () {
        t._toastEl.trigger("afterShown");
      }) : (this || _global)._toastEl.show(function () {
        t._toastEl.trigger("afterShown");
      }), this.canAutoHide()) {
        var t = this || _global;
        o.setTimeout(function () {
          "fade" === t.options.showHideTransition.toLowerCase() ? (t._toastEl.trigger("beforeHide"), t._toastEl.fadeOut(function () {
            t._toastEl.trigger("afterHidden");
          })) : "slide" === t.options.showHideTransition.toLowerCase() ? (t._toastEl.trigger("beforeHide"), t._toastEl.slideUp(function () {
            t._toastEl.trigger("afterHidden");
          })) : (t._toastEl.trigger("beforeHide"), t._toastEl.hide(function () {
            t._toastEl.trigger("afterHidden");
          }));
        }, (this || _global).options.hideAfter);
      }
    },
    reset: function (o) {
      "all" === o ? t(".jq-toast-wrap").remove() : (this || _global)._toastEl.remove();
    },
    update: function (t) {
      this.prepareOptions(t, (this || _global).options), this.setup(), this.bindToast();
    }
  };
  t.toast = function (t) {
    var o = Object.create(n);
    return o.init(t, this || _global), {
      reset: function (t) {
        o.reset(t);
      },
      update: function (t) {
        o.update(t);
      }
    };
  }, t.toast.options = {
    text: "",
    heading: "",
    showHideTransition: "fade",
    allowToastClose: !0,
    hideAfter: 3000,
    loader: !0,
    loaderBg: "#9EC600",
    stack: 5,
    position: "bottom-left",
    bgColor: !1,
    textColor: !1,
    textAlign: "left",
    icon: !1,
    beforeShow: function () {},
    afterShown: function () {},
    beforeHide: function () {},
    afterHidden: function () {}
  };
}(jQuery, window, document);
export default {};